import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../../../styles/careers/Application.module.scss";
import { RecruitNewbiewsLayout } from "../../../organisms/careers/RecruitNewbiewsLayout";
import { Link } from "gatsby";
import { NewOpportunitiesHeader } from "../../../organisms/menus/NewOpportunitiesHeader";
import { LinkMargin2 } from "../../../atoms/LinkMargin2";
import { LinkMargin } from "../../../atoms/LinkMargin";
import QrIcon from "../../../images/entryLineQr.png";
import { SEO } from "../../../atoms/seo/SEO";
import seoImage from "../../../images/career_new_top/_kpo0802_newgraduate_head.jpg";
import FadeIn from "../../../atoms/fadein/FadeIn";
import { MAX_WINDOW_HEIGHT, THLESHOLD_WIDTH_SP } from ".";
import Drawer from "../../../atoms/drawer";

export default ({ location: { pathname } }) => {
  const TOP_OTHER_DOM_HEIGHT = 144;
  let innerHeight = 960;
  if (typeof window !== "undefined") {
    innerHeight = window.innerHeight;
  }
  const [titleHeight, setTitleHeight] = useState(innerHeight);
  const [isSp, setIsSp] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsSp(window.innerWidth < THLESHOLD_WIDTH_SP);

      if (!isSp) {
        const topImageDom = document.querySelector(
          "[data-career-top-image=data-career-top-image]"
        );
        const topImageDomHeight = topImageDom?.getBoundingClientRect().height;
        const innerHeight =
          window.innerHeight <= MAX_WINDOW_HEIGHT
            ? window.innerHeight
            : MAX_WINDOW_HEIGHT;

        if (topImageDomHeight) {
          setTitleHeight(
            innerHeight - topImageDomHeight - TOP_OTHER_DOM_HEIGHT
          );
        }
      }
    }, 300);

    window.addEventListener("resize", () => {
      setIsSp(window.innerWidth < THLESHOLD_WIDTH_SP);

      setTimeout(() => {
        if (!isSp) {
          const topImageDom = document.querySelector(
            "[data-career-top-image=data-career-top-image]"
          );
          const topImageDomHeight = topImageDom?.getBoundingClientRect().height;
          const innerHeight =
            window.innerHeight <= MAX_WINDOW_HEIGHT
              ? window.innerHeight
              : MAX_WINDOW_HEIGHT;

          if (topImageDomHeight) {
            setTitleHeight(
              innerHeight - topImageDomHeight - TOP_OTHER_DOM_HEIGHT
            );
          }
        }
      }, 300);
    });
  }, []);

  return (
    <RecruitNewbiewsLayout isSp={isSp} current="opportunities">
      <SEO
        pathname={pathname}
        title="新卒採用情報 | Career Opportunities"
        description="スプリーブホールディングス株式会社の採用ページです。求人情報を記載しております。採用基準は「友達になれそうな人」！元芸人が創った会社が就職企業人気ランキング人材部門3位！未上場成長率1位の注目企業！エントリーはこちらから！"
        image={seoImage}
      />
      <div className={styles.Opportunities}>
        <FadeIn>
          <div
            className={styles.Opportunities__aboutTitle}
            style={!isSp ? { height: titleHeight } : {}}
          >
            <h2>Career Opportunities</h2>
            <h3>採用情報</h3>
          </div>
        </FadeIn>

        <NewOpportunitiesHeader />

        <LinkMargin2 />
        <div className={styles.Opportunities__jobDescription}>
          <a
            id="Description"
            data-career-page-section-index="0"
            style={{ position: "absolute", marginTop: "-120px" }}
          />
          <div className={styles.Opportunities__jobDescriptionTitle}>
            <h2>
              Job Description<span>募集要項</span>
            </h2>
          </div>

          <div className={styles.Opportunities__jobDescriptionItem}>
            <div className={styles.Opportunities__jobDescriptionItemLeft}>
              <p className={styles.Opportunities__jobDescriptionItemLeftTitle}>
                雇用形態
              </p>
            </div>
            <div className={styles.Opportunities__jobDescriptionItemRight}>
              <p className={styles.Opportunities__jobDescriptionItemRightTitle}>
                正社員
              </p>
              <p className={styles.Opportunities__jobDescriptionItemRightText}>
                ※6ヵ月間は試用期間
                <br />
                （条件は本採用時と変更なし）
              </p>
            </div>
          </div>

          <div className={styles.Opportunities__jobDescriptionItem}>
            <div className={styles.Opportunities__jobDescriptionItemLeft}>
              <p className={styles.Opportunities__jobDescriptionItemLeftTitle}>
                募集職種
              </p>
            </div>
            <div className={styles.Opportunities__jobDescriptionItemRight}>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>総合職</p>
              </div>
              <div
                className={
                  styles.Opportunities__jobDescriptionItemRightTextList
                }
              >
                <p>・オリジナルブランドの企画開発・マーケター</p>
                <p>・人事コンサルタント</p>
                <p>・WEBマーケター</p>
              </div>
            </div>
          </div>

          <div className={styles.Opportunities__jobDescriptionItem}>
            <div className={styles.Opportunities__jobDescriptionItemLeft}>
              <p className={styles.Opportunities__jobDescriptionItemLeftTitle}>
                応募資格
              </p>
            </div>
            <div className={styles.Opportunities__jobDescriptionItemRight}>
              <p className={styles.Opportunities__jobDescriptionItemRightTitle}>
                大学、大学院卒業見込みの方（2023年卒対象）
              </p>
            </div>
          </div>

          <div className={styles.Opportunities__jobDescriptionItem}>
            <div className={styles.Opportunities__jobDescriptionItemLeft}>
              <p className={styles.Opportunities__jobDescriptionItemLeftTitle}>
                勤務予定地
              </p>
            </div>
            <div className={styles.Opportunities__jobDescriptionItemRight}>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>大阪本社（グランフロント大阪　北館6階）</p>
              </div>
            </div>
          </div>

          <div className={styles.Opportunities__jobDescriptionItem}>
            <div className={styles.Opportunities__jobDescriptionItemLeft}>
              <p className={styles.Opportunities__jobDescriptionItemLeftTitle}>
                給与
              </p>
            </div>
            <div className={styles.Opportunities__jobDescriptionItemRight}>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>大阪本社</p>
              </div>
              <div
                className={classNames(
                  styles.Opportunities__jobDescriptionItemRightTextList,
                  styles.Opportunities__jobDescriptionItemRightTextList_baseColored
                )}
              >
                <p>月給200,000円〜</p>
              </div>
            </div>
          </div>

          <div className={styles.Opportunities__jobDescriptionItem}>
            <div className={styles.Opportunities__jobDescriptionItemLeft}>
              <p className={styles.Opportunities__jobDescriptionItemLeftTitle}>
                昇給
              </p>
            </div>
            <div className={styles.Opportunities__jobDescriptionItemRight}>
              <p className={styles.Opportunities__jobDescriptionItemRightTitle}>
                年に1回
              </p>
              <p className={styles.Opportunities__jobDescriptionItemRightText}>
                ※圧倒的なパフォーマンスを発揮した場合、昇給の時期を待たずに昇格することもある。
              </p>
            </div>
          </div>

          <div className={styles.Opportunities__jobDescriptionItem}>
            <div className={styles.Opportunities__jobDescriptionItemLeft}>
              <p className={styles.Opportunities__jobDescriptionItemLeftTitle}>
                勤務時間
              </p>
            </div>
            <div className={styles.Opportunities__jobDescriptionItemRight}>
              <p className={styles.Opportunities__jobDescriptionItemRightTitle}>
                9:00-18:00、10：00-19:00、11:00-20:00（休憩60分）
              </p>
              <p className={styles.Opportunities__jobDescriptionItemRightText}>
                ※配属先により異なる
                <br />
                ※残業時間：月平均8.3h（2年度1～6月迄の平均残業時間）
              </p>
            </div>
          </div>

          <div className={styles.Opportunities__jobDescriptionItem}>
            <div className={styles.Opportunities__jobDescriptionItemLeft}>
              <p className={styles.Opportunities__jobDescriptionItemLeftTitle}>
                休日休暇
              </p>
            </div>
            <div className={styles.Opportunities__jobDescriptionItemRight}>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>完全週休2日制</p>
              </div>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>有給休暇</p>
              </div>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>慶弔休暇</p>
              </div>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>産・育休暇</p>
              </div>
            </div>
          </div>

          <div className={styles.Opportunities__jobDescriptionItem}>
            <div className={styles.Opportunities__jobDescriptionItemLeft}>
              <p className={styles.Opportunities__jobDescriptionItemLeftTitle}>
                諸手当
              </p>
            </div>
            <div className={styles.Opportunities__jobDescriptionItemRight}>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>通勤交通費支給有り</p>
              </div>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>役職手当</p>
              </div>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>職能手当</p>
              </div>
            </div>
          </div>

          <div className={styles.Opportunities__jobDescriptionItem}>
            <div className={styles.Opportunities__jobDescriptionItemLeft}>
              <p className={styles.Opportunities__jobDescriptionItemLeftTitle}>
                待遇/福利厚生
              </p>
            </div>
            <div className={styles.Opportunities__jobDescriptionItemRight}>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>
                  社内割引制度（Amazonで大人気の自社製品が社割価格で購入価格）
                </p>
              </div>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>
                  事業立案制度（誰でも新規事業を会社に提案する機会があります）
                </p>
              </div>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>
                  FA制度（キャリアチェンジが可能な制度。募集にタイミングに合わせ、他事業部へ異動希望が出せます）
                </p>
              </div>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>
                  ノーマライゼーション制度（障がい者手帳をお持ちの方に安心して働いていただく制度）
                </p>
              </div>
            </div>
          </div>

          <div className={styles.Opportunities__jobDescriptionItem}>
            <div className={styles.Opportunities__jobDescriptionItemLeft}>
              <p className={styles.Opportunities__jobDescriptionItemLeftTitle}>
                研修制度
              </p>
            </div>
            <div className={styles.Opportunities__jobDescriptionItemRight}>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>新入社員研修</p>
              </div>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>コンプライアンス研修</p>
              </div>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>商品知識座学</p>
              </div>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>伝える力1.7倍ボイトレ研修</p>
              </div>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>コミュニケーション向上心理学研修</p>
              </div>
              <div
                className={styles.Opportunities__jobDescriptionItemRightTitle}
              >
                <p>小説家に学ぶ言葉の整理学</p>
              </div>
              <p className={styles.Opportunities__jobDescriptionItemRightText}>
                ※過去に実際に実施した研修です
              </p>
            </div>
          </div>

          <div className={styles.Opportunities__jobDescriptionItem}>
            <div className={styles.Opportunities__jobDescriptionItemLeft}>
              <p className={styles.Opportunities__jobDescriptionItemLeftTitle}>
                保険
              </p>
            </div>
            <div className={styles.Opportunities__jobDescriptionItemRight}>
              <p className={styles.Opportunities__jobDescriptionItemRightTitle}>
                社会保険完備（雇用・労災・健康・厚生年金）
              </p>
            </div>
          </div>

          <div className={styles.idealGray}>
            <a
              id="Ideal"
              data-career-page-section-index="1"
              style={{ position: "absolute", marginTop: "-120px" }}
            />
            <div className={styles.idealTitle}>
              <h2>
                Ideal Candidate Profile<span>求める人物像</span>
              </h2>
            </div>
          </div>

          <div className={styles.idealContainer}>
            <div className={styles.idealText}>
              <h2 className={styles.idealTextTitle}>
                仲間と協調し、
                <br />
                変化し続けることができる人
              </h2>
              <h3>「この上ない最高の世界を創る」</h3>
              <p>
                ビジョンは１つでも、そこへ繋がる道はいくらでもあります。
                <br />
                世界の状況に合わせ、柔軟に形や方法を変化させながら、ともに向かえる仲間を募集します。
              </p>
            </div>
            <StaticImage
              className={styles.idealImg}
              src="../../../images/career_new_opportunities/idealCandidateProfile.png"
              alt=""
            />
          </div>

          <div className={styles.idealWhite}>
            <h2 className={styles.idealTextTitle}>人事からのメッセージ</h2>
            <h3>”最高”ってなんだろう？と問い続けよう。</h3>
            <p>
              世界にとって、お客様にとって、一緒に働く仲間にとって、そして自分自身にとって、最高って何だろう？
              <br />
              どうすれば近づけるだろう？
              <br />
              ひとつひとつの進化を皆で喜びながら、常に自分の武器を磨き続けていきたい。
              <br />
              想像を越える感動を世界に、お客様に、仲間に味わい続けてもらうために、
              <br />
              私たちは、いつまでも満足せずに、高め続けられる組織で在りたい。
            </p>
          </div>
        </div>

        <div className={styles.flowTitle}>
          <div className={styles.flow}>
            <a
              id="Flow"
              data-career-page-section-index="2"
              style={{ position: "absolute", marginTop: "-120px" }}
            />
            <h2>
              Selection Flow<span>応募・選考フロー</span>
            </h2>
            <p>
              まずは<Link to={"#"}>エントリー</Link>をお願いします。
              <br />
              エントリーいただいた方に会社説明会・選考情報などをご案内させていただきます。
            </p>
            <ul className={styles.FlowSteps}>
              <li className={styles.FlowSteps__step}>
                <div className={styles.FlowSteps__stepBody}>
                  <div className={styles.FlowSteps__stepBodyInner}>
                    <p className={styles.FlowSteps__stepText}>Step1</p>
                    <div className={styles.FlowSteps__stepLine} />
                    <p className={styles.FlowSteps__stepContent}>書類選考</p>
                  </div>
                </div>
                <div className={styles.FlowSteps__stepArrowBottom} />
              </li>
              <li className={styles.FlowSteps__step}>
                <div className={styles.FlowSteps__stepBody}>
                  <div className={styles.FlowSteps__stepBodyInner}>
                    <p className={styles.FlowSteps__stepText}>Step2</p>
                    <div className={styles.FlowSteps__stepLine} />
                    <p className={styles.FlowSteps__stepContent}>
                      1on1 Web面接
                    </p>
                  </div>
                </div>
                <div className={styles.FlowSteps__stepArrowBottom} />
              </li>
              <li className={styles.FlowSteps__step}>
                <div className={styles.FlowSteps__stepBody}>
                  <div className={styles.FlowSteps__stepBodyInner}>
                    <p className={styles.FlowSteps__stepText}>Step3</p>
                    <div className={styles.FlowSteps__stepLine} />
                    <p className={styles.FlowSteps__stepContent}>
                      役員面接（1〜2回）
                    </p>
                  </div>
                </div>
                <div className={styles.FlowSteps__stepArrowBottom} />
              </li>
              <li className={styles.FlowSteps__step}>
                <div className={styles.FlowSteps__stepBody}>
                  <div className={styles.FlowSteps__stepBodyInner}>
                    <p className={styles.FlowSteps__stepText}>Step4</p>
                    <div className={styles.FlowSteps__stepLine} />
                    <p className={styles.FlowSteps__stepContent}>内定</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <a
          id="Faq"
          data-career-page-section-index="3"
          style={{ position: "absolute", marginTop: "-120px" }}
        />
        <LinkMargin />
        <LinkMargin2 />
        <div className={styles.faq}>
          <h2>
            FAQ<span>よくある質問</span>
          </h2>
          <p>皆さんから多く寄せられたご質問について、回答を掲載しています。</p>
          <div className={styles.anchorBox}>
            <Link to="#oubo">
              <h5>応募について</h5>
            </Link>
            <Link to="#saiyou">
              <h5>採用・選考について</h5>
            </Link>
            <Link to="#shigoto">
              <h5>仕事について</h5>
            </Link>
            <Link to="#sonota">
              <h5>その他</h5>
            </Link>
          </div>
        </div>

        <div className={styles.acd}>
          <a id="oubo" />
          <h2>応募について</h2>
          <Drawer
            items={[
              {
                mainText:
                  "Q.当WEBサイトにも掲載されている職種以外の募集はありますか？",
                subTexts: [
                  "A.場合によって他にも募集を行うこともございますが、基本的には当サイトの通りです。",
                ],
              },
            ]}
            className={styles.drawer}
          />
        </div>

        <div className={styles.acd}>
          <a id="saiyou" />
          <h2>採用・選考について</h2>
          <Drawer
            items={[
              {
                mainText:
                  "Q.インターネットの知識や技術的なスキル、資格は必要ですか？",
                subTexts: [
                  "A.入社後にしっかりと身に付けていただきますため、特に必須の知識、スキル、資格はございません。",
                  "急成長を続けており新規事業を頻繁に生まれる状況のため、今お持ちのスキル、また今後取得予定の資格等ございましたら今後に活きる可能性があります。",
                ],
              },
              {
                mainText: "Q.筆記テストはありますか？",
                subTexts: ["A.特にございません。"],
              },
              {
                mainText: "Q.リモートでの面接は可能ですか？",
                subTexts: [
                  "A.はい、可能です。2020年3月より全面的にオンライン面接に切り替えております。",
                ],
              },
              {
                mainText: "Q.18時以降、もしくは土日祝の面接は可能ですか。",
                subTexts: [
                  "A.場合によっては18時以降、土日祝の面接も可能です。",
                  "是非一度ご相談ください。",
                ],
              },
            ]}
            className={styles.drawer}
          />
        </div>

        <div className={styles.acd}>
          <a id="shigoto" />
          <h2>仕事について</h2>
          <Drawer
            items={[
              {
                mainText: "Q.行きたい部署の希望は出せますか。",
                subTexts: [
                  "A.ご希望をお伝えいただくことはもちろん可能ですが、配属先は基本的には募集している部署での配属となります。",
                  "また入社後、異動の可能性もございます。",
                ],
              },
              {
                mainText: "Q.試用期間はありますか？",
                subTexts: ["A.入社してから6ヵ月間が試用期間となります。"],
              },
            ]}
            className={styles.drawer}
          />
        </div>

        <div className={styles.acd}>
          <a id="sonota" />
          <h2>その他</h2>
          <Drawer
            items={[
              {
                mainText: "Q.社員寮／家賃補助はありますか？",
                subTexts: [
                  "A.社員寮、家賃補助ともに現時点ではございません。",
                  "転居を伴う転勤はございませんが、お引越しをされる際のサポートがございます。",
                ],
              },
              {
                mainText: "Q.部署間を越えた社員同士の交流はありますか？",
                subTexts: [
                  "A.現在は新型コロナウィルス感染拡大防止のため実施を中断しておりますが、様々なイベントを開催しております。",
                  "運動会、ハロウィンパーティー、クリスマスパーティー、カラオケ大会、新年度、忘年会etc...",
                ],
              },
            ]}
            className={styles.drawer}
          />
        </div>

        <div className={styles.button}>
          <p>
            その他のご質問に関してはコンタクトページよりメールにてお問い合わせください。
          </p>
          <div className={styles.viewAll}>
            <Link to="/contact?type=careers">Contact</Link>
          </div>
        </div>
      </div>
    </RecruitNewbiewsLayout>
  );
};
