import React, { FunctionComponent, useState } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { ENTERED, EXITING } from "react-transition-group/Transition";
import * as styles from "./style.module.scss";

const DEFAULT_ANIMATION_DURATION = 250;

const ANIMATION_CLASS_NAMES = {
  ENTERED: styles.Drawer__subText__entered,
  EXITING: styles.Drawer__subText__exiting,
} as const;

function getModifierClassNames(state: string) {
  switch (state) {
    case ENTERED:
      return ANIMATION_CLASS_NAMES.ENTERED;
    case EXITING:
      return ANIMATION_CLASS_NAMES.EXITING;
  }
}

type DrawereItemProps = {
  mainText: string;
  subTexts: string[];
};

type DrawerProps = {
  items: DrawereItemProps[];
  className?: string;
};

const DrawerItem: FunctionComponent<DrawereItemProps> = ({
  mainText,
  subTexts,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={styles.Drawer__item}
      style={isOpen ? { height: "108px" } : undefined}
    >
      <button
        className={styles.Drawer__itemButton}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <p>{mainText}</p>

        <div className={styles.Drawer__icon}>
          <div
            className={classNames(
              styles.Drawer__borderVertical,
              isOpen ? styles.Drawer__borderVertical__hidden : ""
            )}
          />
          <div
            className={classNames(
              styles.Drawer__borderHorizontal,
              isOpen ? styles.Drawer__borderHorizontal__rotated : ""
            )}
          />
        </div>
      </button>
      <CSSTransition
        in={isOpen}
        timeout={DEFAULT_ANIMATION_DURATION}
        unmountOnExit
        // react-transition-groupのデフォルトclassNameを出力しないようにする
        classNames={{}}
      >
        {state => (
          <div
            className={classNames(
              styles.Drawer__subText,
              getModifierClassNames(state)
            )}
          >
            {subTexts.map((subText, index) => (
              <p key={index}>{subText}</p>
            ))}
          </div>
        )}
      </CSSTransition>
    </div>
  );
};

const Drawer: FunctionComponent<DrawerProps> = ({ items, className }) => (
  <ul className={classNames(styles.Drawer, className)}>
    {items.map((item, index) => (
      <DrawerItem
        key={index}
        mainText={item.mainText}
        subTexts={item.subTexts}
      />
    ))}
  </ul>
);

export default Drawer;
