import React from "react";
import PageInnerNavigator, {
  PageInnerNavigatorItemProps,
} from "./careers/new/pageInnerNavigator";

const links: PageInnerNavigatorItemProps[] = [
  {
    anchor: "#Description",
    name: "募集要項",
  },
  {
    anchor: "#Ideal",
    name: "求める人物像",
  },
  {
    anchor: "#Flow",
    name: "応募・選考フロー",
  },
  {
    anchor: "#Faq",
    name: "FAQ",
  },
];

export const NewOpportunitiesHeader = () => (
  <PageInnerNavigator items={links} />
);
