// extracted by mini-css-extract-plugin
export var cover = "t_dS";
export var aboutTitle = "t_gB";
export var Opportunities = "t_hP";
export var Opportunities__aboutTitle = "t_hQ";
export var Opportunities__jobDescription = "t_hR";
export var Opportunities__jobDescriptionTitle = "t_hS";
export var Opportunities__jobDescriptionItem = "t_hT";
export var Opportunities__jobDescriptionItemLeft = "t_hV";
export var Opportunities__jobDescriptionItemLeftTitle = "t_hW";
export var Opportunities__jobDescriptionItemRight = "t_hX";
export var Opportunities__jobDescriptionItemRightTitle = "t_hY";
export var Opportunities__jobDescriptionItemRightText = "t_hZ";
export var Opportunities__jobDescriptionItemRightTextList = "t_h0";
export var Opportunities__jobDescriptionItemRightTextList_baseColored = "t_h1";
export var applicationContainerLast = "t_h2";
export var applicationContainerRightfontweight = "t_h3";
export var applicationContainerRightfontweight1 = "t_h4";
export var applicationContainerRightfontweighthaifun = "t_h5";
export var profileTitle = "t_h6";
export var profileGray = "t_h7";
export var profileContainer = "t_h8";
export var profileContainerLeft = "t_h9";
export var profileImg = "t_jb";
export var idealTitle = "t_jc";
export var idealGray = "t_jd";
export var idealContainer = "t_jf";
export var idealImg = "t_jg";
export var idealWhite = "t_jh";
export var idealWhiteContainer = "t_jj";
export var flowTitle = "t_jk";
export var flow = "t_jl";
export var flowContainer = "t_jm";
export var flowBoxOne = "t_jn";
export var triangle = "t_fQ";
export var information = "t_jp";
export var entry = "t_gZ";
export var entryTitle = "t_jq";
export var entryBox = "t_jr";
export var entryContainer = "t_js";
export var entryContainerLeft = "t_jt";
export var entryImg = "t_jv";
export var entryContainerSmall = "t_jw";
export var entryImgSmall = "t_jx";
export var InformationBox = "t_jy";
export var infomationContainer = "t_jz";
export var lineImg = "t_jB";
export var qrImg = "t_jC";
export var flex1 = "t_hk";
export var none = "t_bn";
export var iconContainer = "t_jD";
export var iconImg = "t_jF";
export var faq = "t_gV";
export var anchorBox = "t_jG";
export var acd = "t_g3";
export var answer = "t_g4";
export var fadein = "t_g5";
export var faqContainer = "t_jH";
export var faqContainerLast = "t_jJ";
export var button = "t_f";
export var viewAll = "t_gy";
export var Entry = "t_fS";
export var Entry__heading = "t_fT";
export var Entry__headingText = "t_fV";
export var Entry__box = "t_fW";
export var Entry__boxText = "t_jK";
export var Entry__boxItems = "t_jL";
export var Entry__containerUpper = "t_jM";
export var Entry__img = "t_fX";
export var Entry__containerSmall = "t_fY";
export var Entry__imgSmall = "t_fZ";
export var Entry__lineText = "t_f0";
export var Entry__lineTextPc = "t_f2";
export var Entry__qrExplain = "t_f3";
export var Entry__containerLower = "t_jN";
export var Entry__entryLinkSp = "t_f5";
export var Entry__messagePc = "t_f6";
export var Entry__messageSp = "t_f7";
export var FlowSteps = "t_jP";
export var FlowSteps__step = "t_jQ";
export var FlowSteps__stepBody = "t_jR";
export var FlowSteps__stepBodyInner = "t_jS";
export var FlowSteps__stepText = "t_jT";
export var FlowSteps__stepLine = "t_jV";
export var FlowSteps__stepContent = "t_jW";
export var FlowSteps__stepArrowBottom = "t_jX";
export var jobDescreptionItemListText = "t_jY";
export var jobDescreptionItemListBoldText = "t_jZ";
export var jobDescreptionItemListTitle = "t_j0";
export var jobDescreptionItemListSubText = "t_j1";
export var jobDescreptionItemListSubText__indented = "t_j2";
export var jobDescreptionItemListSubTexts = "t_j3";
export var idealText = "t_j4";
export var idealTextTitle = "t_j5";
export var drawer = "t_g6";