// extracted by mini-css-extract-plugin
export var Drawer = "K_m1";
export var Drawer__item = "K_m2";
export var Drawer__itemButton = "K_m3";
export var Drawer__borderVertical = "K_m4";
export var Drawer__borderVertical__hidden = "K_m5";
export var Drawer__borderHorizontal = "K_m6";
export var Drawer__borderHorizontal__rotated = "K_m7";
export var Drawer__subText = "K_m8";
export var Drawer__subText__entered = "K_m9";
export var Drawer__subText__exiting = "K_nb";